.treatments {
    .know-more {
        display: none;
    }
    .most-popular-treatments {
        @media screen and (max-width: 1024px) {
            margin: 25px 0;
        }
        .wrapper {
            .header {
                @media screen and (max-width: 1024px) {
                    margin: 50px 0;
                }
            }
        }
    }
    .showcase {
        .purple-strip {
            padding: 84px 0;
            background-color: $theme-purple;
            position: relative;
            @media screen and (max-width: 1024px) {
                padding: 120px 0 50px;
            }
            .wrapper {
                max-width: 1193px;
                margin: 0 auto;
                position: relative;
                @media screen and (max-width: 1280px) {
                    max-width: none;
                    padding: 0 30px;
                }
                @media screen and (max-width: 1024px) {
                    padding: 0 30px;
                }
                .heading {
                    color: $white;
                    h1 {
                        margin-bottom: 5px;
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 1.48;
                        letter-spacing: -0.8px;
                        word-break: break-all;
                    }
                    p {
                        font-size: 26px;
                        line-height: 1.54;
                        max-width: 300px;
                    }
                    .bg {
                        position: absolute;
                        top: -89px;
                        right: -136px;
                    }
                }
                .form-section {
                    position: absolute;
                    top: -45px;
                    right: -30px;
                }
            }
        }
        .green-strip {
            height: 80px;
            background-color: $theme-teal;
            position: relative;
            @media screen and (max-width: 768px) {
                height: 30px;
            }
            .bg {
                position: absolute;
                bottom: 0;
                left: -133px;
                right: 0;
                margin: 0 auto;
                z-index: 3;
                @media screen and (max-width: 1280px) {
                    display: none;
                }
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }
    .treatments-container {
        margin-top: 131px;
        @media screen and (max-width: 1024px) {
            margin-top: 50px;
        }
        .wrapper {
            max-width: 1193px;
            margin: 0 auto 0;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            .row {
                display: grid;
                grid-template-columns: auto 806px;
                @media screen and (max-width: 1024px) {
                    display: block;
                }
                .treatment-nav {
                    padding-right: 54px;
                    @media screen and (max-width: 1024px) {
                        display: none;
                    }
                    ul {
                        li {
                            margin-bottom: 40px;
                            font-size: 23px;
                            line-height: 1.4;
                            a {
                                display: flex;
                                align-items: center;
                            }
                            .icon {
                                margin-right: 37px;
                                display: flex;
                                svg {
                                    width: 47px;
                                    height: 47px;
                                }
                            }
                            &._active {
                                color: $theme-purple;
                                svg {
                                    .default-fill-color {
                                        fill: $theme-purple;
                                    }
                                }
                            }
                            &:hover {
                                color: $theme-purple;
                                svg {
                                    .default-fill-color {
                                        fill: $theme-purple;
                                    }
                                }
                            }
                        }
                    }
                }
                .treatment-content {
                    font-size: 20px;
                    line-height: 1.5;
                    .treatment-title {
                        font-weight: bold;
                    }
                    .treatment-content {
                        margin: 27px 0 30px;
                    }
                    .treatment-img {
                        img {
                            @media screen and (max-width: 1024px) {
                                width: 100%;
                            }
                        }
                    }
                    p {
                        margin-bottom: 15px;
                    }
                    .treatment-list {
                        margin-top: 34px;
                        ul li {
                            position: relative;
                            padding-left: 67px;
                            @media screen and (max-width: 1024px) {
                                padding-left: 40px;
                            }
                            .bullet {
                                position: absolute;
                                top: 10px;
                                left: 40px;
                                width: 10px;
                                height: 10px;
                                background-color: $bullet-bg;
                                border-radius: 50%;
                                @media screen and (max-width: 1024px) {
                                    left: 10px;
                                }
                            }
                            .mini-bullet {
                                position: absolute;
                                top: 10px;
                                left: 40px;
                                width: 10px;
                                height: 10px;
                                background-color: $bullet-bg;
                                border-radius: 50%;
                                @media screen and (max-width: 1024px) {
                                    left: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .free-consultation {
        margin-top: 233px;
        padding: 48px 0 44px;
        background-color: $theme-teal;
        text-align: center;
        @media screen and (max-width: 1024px) {
            margin-top: 80px;
            padding: 38px 0;
        }
        @media screen and (max-width: 768px) {
            margin-top: 80px;
            padding: 38px 30px;
        }
        p {
            line-height: 69px;
            font-size: 58px;
            color: $white;
            margin-bottom: 27px;
            @media screen and (max-width: 1024px) {
                line-height: 50px;
                font-size: 40px;
            }
            @media screen and (max-width: 768px) {
                line-height: 40px;
                font-size: 30px;
            }
        }
    }
    .treatments-landing {
        margin-top: 240px;
        @media screen and (max-width: 1024px) {
            margin-top: 0px;
        }
    }
}