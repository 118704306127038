.join-our-network {
    .showcase {
        .purple-strip {
            padding: 70px 0;
            background-color: $theme-purple;
            overflow: hidden;
            @media screen and (max-width: 1024px) {
                padding: 116px 30px 46px;
            }
            .wrapper {
                max-width: 1193px;
                margin: 0 auto;
                position: relative;
                .heading {
                    color: $white;
                    h1 {
                        margin-bottom: 5px;
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 1.48;
                        letter-spacing: -0.8px;
                        word-break: break-all;
                    }
                    p {
                        font-size: 26px;
                        line-height: 1.54;
                        max-width: 531px;
                    }
                    .bg {
                        position: absolute;
                        top: -89px;
                        right: -100px;
                        @media screen and (max-width: 1024px) {
                            display: none;
                        }
                    }
                    .landing-bg {
                        position: absolute;
                        top: -70px;
                        right: 0;
                        // width: 165px;
                        height: 246px;
                        @media screen and (max-width: 1024px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .join-our-network-nav {
        background-color: $theme-teal;
        position: relative;
        ul {
            max-width: 1193px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: auto auto auto auto;
            @media screen and (max-width: 1280px) {
                max-width: none;
                padding: 0 30px;
                text-align: center;
            }
            @media screen and (max-width: 1024px) {
                grid-template-columns: 50% 50%;
                text-align: center;
            }
            @media screen and (max-width: 700px) {
                grid-template-columns: 100%;
                text-align: center;
            }
            li {
                padding: 20px 0;
                font-size: 18px;
                font-weight: 500;
                line-height: 3.33;
                color: $white;
                @media screen and (max-width: 700px) {
                    padding: 0 0 20px;
                    &:first-child {
                        padding-top: 20px;
                    }
                }
                a {
                    &:hover {
                        color: $secondary-nav-active;
                    }
                }
                &._active {
                    a {
                        color: $secondary-nav-active;
                    }
                }
            }
        }
    }
    .page-title {
        margin: 85px 0 49px;
        @media screen and (max-width: 1024px) {
            margin: 35px 0;
        }
        .wrapper {
            max-width: 1098px;
            margin: 0 auto;
            font-size: 30px;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
                font-size: 26px;
            }
        }
    }
    .page-content {
        padding-bottom: 72px;
        @media screen and (max-width: 1024px) {
            padding-bottom: 30px;
        }
        .wrapper {
            max-width: 1098px;
            margin: 0 auto;
            font-size: 18px;
            line-height: 1.5;
            font-size: 20px;
            line-height: 2;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
                font-size: 16px;
            }
            .page-grid {
                display: grid;
                grid-template-columns: 50% auto;
                grid-column-gap: 80px;
                @media screen and (max-width: 1024px) {
                    grid-template-columns: 100%;
                }
                .content {}
                .img-sec {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: 1024px) {
                        margin: 50px 0;
                    }
                    @media screen and (max-width: 400px) {
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .img-section {
        margin-top: 20px;
        margin-bottom: 80px;
        @media screen and (max-width: 1024px) {
            margin-bottom: 50px;
        }
        .wrapper {
            max-width: 1098px;
            margin: 0 auto;
            position: relative;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            img {
                max-width: 800px;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }
            .form-section {
                position: absolute;
                bottom: 15px;
                right: 0;
                top: auto;
                @media screen and (max-width: 1024px) {
                    position: static;
                }
            }
            .blue-block {
                position: absolute;
                top: -30px;
                right: 216px;
                background-color: $theme-purple;
                width: 267px;
                height: 294px;
                box-shadow: 20px 30px 30px 0 rgba(0, 0, 0, 0.16);
                background-color: $theme-purple;
                border-top-left-radius: 5px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 50px;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
            .pink-block {
                position: absolute;
                top: -8px;
                right: -14px;
                background-color: $form-pink;
                width: 186px;
                height: 186px;
                border-radius: 68px;
                background-color: #fc57c9;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }
    .benefits {
        padding: 61px 0 20px;
        background-color: #f7fdfc;
        text-align: center;
        .wrapper {
            max-width: 1193px;
            margin: 0 auto;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            .title {
                font-size: 36px;
                letter-spacing: -0.04px;
                margin-bottom: 55px;
            }
            ul {
                display: grid;
                grid-template-columns: 25% 25% 25% 25%;
                @media screen and (max-width: 1024px) {
                    grid-template-columns: 50% 50%;
                }
                @media screen and (max-width: 768px) {
                    grid-template-columns: 100%;
                }
                &.level-two {
                    max-width: 871px;
                    margin: 70px auto 0;
                    grid-template-columns: 33.3333% 33.3333% 33.3333%;
                    @media screen and (max-width: 768px) {
                        grid-template-columns: 100%;
                    }
                }
                &.level-three {
                    max-width: 564px;
                    margin: 70px auto 0;
                    grid-template-columns: 50% 50%;
                    @media screen and (max-width: 600px) {
                        grid-template-columns: 100%;
                    }
                }
                &.level-four {
                    margin: 70px auto 0;
                    grid-template-columns: 100%;
                }
                li {
                    padding: 0 25px;
                    margin-bottom: 30px;
                    .icon {
                        margin-bottom: 40px;
                        img {
                            width: 55px;
                            height: 55px;
                        }
                    }
                    .text {
                        max-width: 257px;
                        margin: 0 auto;
                        font-family: $font-dos;
                        font-size: 16px;
                        line-height: 1.75;
                        letter-spacing: -0.03px;
                    }
                }
            }
        }
    }
    .free-consultation {
        padding: 48px 0 44px;
        background-color: $theme-teal;
        text-align: center;
        @media screen and (max-width: 1024px) {
            padding: 38px 0;
        }
        @media screen and (max-width: 768px) {
            padding: 38px 30px;
        }
        p {
            line-height: 69px;
            font-size: 58px;
            color: $white;
            margin-bottom: 27px;
            @media screen and (max-width: 1024px) {
                line-height: 50px;
                font-size: 40px;
            }
            @media screen and (max-width: 768px) {
                line-height: 40px;
                font-size: 30px;
            }
        }
    }
}