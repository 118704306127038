footer {
    padding: 44px 0 52px;
    background-color: $footer-bg-color;
    @media screen and (max-width: 1024px) {
        padding: 25px 0 22px;
    }
    .wrapper {
        max-width: 1062px;
        margin: 0 auto;
        @media screen and (max-width: 1200px) {
            max-width: none;
            padding: 0 30px;
        }
        .responsive-logo {
            display: none;
            @media screen and (max-width: 1024px) {
                display: block;
                margin-bottom: 25px;
            }
        }
        .top-nav {
            @media screen and (max-width: 1024px) {
                display: grid;
                grid-template-columns: 50% 50%;
            }
            .footer-links {
                border-bottom: 1px solid #d6dbdb;
                padding-bottom: 25px;
                line-height: 24px;
                font-size: 20px;
                @media screen and (max-width: 1024px) {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
                .nav-element {
                    display: inline-block;
                    margin-right: 33px;
                    @media screen and (max-width: 1024px) {
                        display: block;
                        margin-right: 0;
                        margin-bottom: 20px;
                    }
                    a {
                        @media screen and (max-width: 1024px) {
                            color: #626564;
                        }
                        &:hover {
                            color: $theme-purple;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                        @media screen and (max-width: 1024px) {
                            display: block;
                            margin-right: 0;
                            margin-bottom: 0px;
                        }
                    }
                }
            }
            .responsive-contact-nav {
                display: none;
                @media screen and (max-width: 1024px) {
                    display: block;
                    margin-right: 0;
                }
                .nav-element {
                    line-height: 24px;
                    font-size: 20px;
                    margin-bottom: 20px;
                    a {
                        color: #626564;
                        &:hover {
                            color: $theme-purple;
                        }
                    }
                }
            }
        }
        .referal {
            padding: 12px 0 19px;
            display: grid;
            @media screen and (max-width: 1024px) {
                display: block;
                padding: 21px 0 20px;
                border-bottom: 1px solid #d6dbdb;
            }
            .logo {
                grid-column: 1;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
            .ref-links {
                grid-column: 2;
                .contact-nav {
                    line-height: 17px;
                    font-size: 14px;
                    text-align: right;
                    @media screen and (max-width: 1024px) {
                        display: none;
                    }
                    .nav-element {
                        display: inline-block;
                        margin-right: 15px;
                        a {
                            &:hover {
                                color: $theme-purple;
                            }
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .social-media {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-top: 12px;
                    @media screen and (max-width: 1024px) {
                        justify-content: flex-start;
                    }
                    li {
                        display: flex;
                        justify-content: center;
                        margin-right: 10px;
                        cursor: pointer;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .policies {
            text-align: right;
            line-height: 24px;
            opacity: 0.6;
            font-family: $font-dos;
            font-size: 12px;
            color: $footer-policy-color;
            @media screen and (max-width: 1024px) {
                text-align: left;
                margin-top: 20px;
            }
        }
    }
}

.grecaptcha-badge {
    visibility: hidden!important;
}

#return-to-top {
    position: fixed;
    bottom: 300px;
    right: 20px;
    background: $theme-purple;
    background: lighten($theme-purple, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    border-radius: 35px;
    display: none;
    transition: all 0.3s ease;
    &:hover {
        background: $theme-purple;
        .chevron {
            top: 15px;
        }
    }
    .chevron {
        position: absolute;
        top: 20px;
        left: 20px;
        transition: all 0.3s ease;
        &::before {
            border-style: solid;
            border-width: 0.25em 0.25em 0 0;
            content: '';
            display: inline-block;
            transform: rotate(-45deg);
            vertical-align: top;
            height: 0.45em;
            width: 0.45em;
            border-color: $white;
        }
    }
}