.success-stories {
    .none {
        display: none;
    }
    .showcase {
        overflow: hidden;
        .wrapper {
            position: relative;
            max-width: 1193px;
            margin: 0 auto;
            height: 552px;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
                height: auto;
            }
            .showcase-container {
                display: grid;
                grid-template-columns: 50% 50%;
                @media screen and (max-width: 1024px) {
                    display: block;
                    padding: 120px 0 50px;
                    height: auto;
                }
                .heading-img {
                    @media screen and (max-width: 1024px) {
                        display: none;
                    }
                }
                .heading-wrapper {
                    display: flex;
                    align-items: center;
                    .heading {
                        max-width: 536px;
                        @media screen and (max-width: 1024px) {
                            max-width: none;
                        }
                    }
                    &._none {
                        display: none;
                    }
                    h1 {
                        font-size: 60px;
                        font-weight: bold;
                        line-height: 0.98;
                        letter-spacing: -1px;
                        @media screen and (max-width: 1024px) {
                            font-size: 54px;
                        }
                    }
                    p {
                        padding: 41px 0 0;
                        font-size: 20px;
                        line-height: 2;
                        @media screen and (max-width: 1024px) {
                            font-size: 18px;
                            line-height: 1.8;
                            padding: 30px 0 0;
                        }
                    }
                }
            }
        }
        .blue-block {
            position: absolute;
            top: -9px;
            right: 215px;
            background-color: $theme-purple;
            width: 267px;
            height: 294px;
            box-shadow: 20px 30px 30px 0 rgba(0, 0, 0, 0.16);
            background-color: $theme-purple;
            border-top-left-radius: 15px;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 50px;
            @media screen and (max-width: 1024px) {
                display: none;
            }
            &._dos {
                right: -20px;
                bottom: 55px;
                top: auto;
            }
        }
        .pink-block {
            position: absolute;
            bottom: 66px;
            right: 280px;
            background-color: $form-pink;
            width: 186px;
            height: 186px;
            border-radius: 68px;
            background-color: #fc57c9;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
        .bg {
            position: absolute;
            right: -100px;
            bottom: 0;
            z-index: -1;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    }
    .video-list {
        margin: 110px 0;
        margin-top: 154px;
        @media screen and (max-width: 1024px) {
            margin: 50px 0 120px;
        }
        .wrapper {
            max-width: 1225px;
            margin: 0 auto;
            @media screen and (max-width: 1280px) {
                max-width: none;
                padding-left: 30px;
                padding-right: 30px;
            }
            ul {
                li {
                    position: relative;
                    padding-bottom: 57px;
                    margin-bottom: 57px;
                    border-bottom: solid 1px #d8d8da;
                    min-height: 250px;
                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: 0;
                    }
                    .image {
                        position: absolute;
                        top: 0;
                        left: 0;
                        @media screen and (max-width: 768px) {
                            position: static;
                            text-align: center;
                            margin-bottom: 25px;
                        }
                        .image-wrapper {
                            position: relative;
                            width: 300px;
                            margin: 0 auto;
                            overflow: hidden;
                            .play-block {
                                cursor: pointer;
                                position: absolute;
                                bottom: 3px;
                                right: 0;
                                width: 71px;
                                height: 71px;
                                background: #fc57c9;
                                .icon {
                                    display: block;
                                    width: 0;
                                    height: 0;
                                    border-top: 18px solid transparent;
                                    border-left: 23px solid white;
                                    border-bottom: 18px solid transparent;
                                    margin: 18px auto 0 29px;
                                }
                            }
                        }
                    }
                    .content {
                        padding-left: 357px;
                        min-height: 250px;
                        @media screen and (max-width: 768px) {
                            padding-left: 0;
                        }
                        .title {
                            font-size: 30px;
                            font-weight: 500;
                            line-height: 1.67;
                            letter-spacing: -0.01px;
                            @media screen and (max-width: 768px) {
                                font-size: 26px;
                            }
                        }
                        .text {
                            font-size: 20px;
                            line-height: 2;
                            @media screen and (max-width: 768px) {
                                font-size: 18px;
                            }
                        }
                        .button {
                            margin-top: 35px;
                            @media screen and (max-width: 768px) {
                                margin-top: 28px;
                            }
                            span {
                                border: solid 2px #79dbc5;
                                font-size: 18px;
                                font-weight: 500;
                                color: #1a6f5c;
                                padding: 19px 19px 16px;
                                cursor: pointer;
                                @media screen and (max-width: 768px) {
                                    font-size: 16px;
                                    padding: 15px 15px 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .feedback-sliders {
        margin-bottom: 100px;
        text-align: center;
        .wrapper {
            max-width: 1225px;
            margin: 0 auto;
            @media screen and (max-width: 1280px) {
                max-width: none;
                padding-left: 30px;
                padding-right: 30px;
            }
            .section-title {
                margin-bottom: 50px;
                font-size: 32px;
            }
            .feedback-container {
                .text {
                    margin-bottom: 15px;
                    font-size: 20px;
                    line-height: 1.5;
                }
                .name {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
            .slick-dots {
                margin-top: 80px;
                justify-content: center;
                @media screen and (max-width: 1280px) {
                    margin-top: 50px;
                }
            }
        }
    }
}

.free-consultation {
    padding: 48px 0 44px;
    background-color: $theme-teal;
    text-align: center;
    @media screen and (max-width: 1024px) {
        padding-left: 30px;
        padding-right: 30px;
    }
    p {
        line-height: 69px;
        font-size: 58px;
        color: $white;
        margin-bottom: 27px;
        @media screen and (max-width: 1024px) {
            line-height: 50px;
            font-size: 40px;
        }
        @media screen and (max-width: 768px) {
            line-height: 40px;
            font-size: 30px;
        }
    }
}