.form-section {
    position: absolute;
    top: -45px;
    right: 0;
    @media screen and (max-width: 1024px) {
        display: none;
    }
    .pink-balloon {
        width: 186px;
        height: 186px;
        border-radius: 68px;
        background-color: $form-pink;
        position: absolute;
        right: -15px;
        top: 15px;
    }
    .form-container {
        position: relative;
        padding: 54px 56px 39px 45px;
        background: $white;
        z-index: 2;
        width: 460px;
        box-shadow: 20px 30px 30px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #c1c1c1;
        background-color: $white;
        border-top-left-radius: 20px;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 80px;
        @media screen and (max-width: 768px) {
            margin: 0 auto;
            box-shadow: none;
            padding: 25px;
        }
        .form-title {
            font-family: $font-dos;
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0px;
            margin-bottom: 15px;
        }
        .inputTxt {
            display: block;
            position: relative;
            width: 100%;
            padding: 10px 0px 0px 0px;
            height: 70px;
            &>* {
                transition: .25s ease-in-out all;
            }
            input {
                display: block;
                width: 100%;
                height: 42px;
                padding: 0px 0px 0px 4px;
                border: none;
                font-size: 18px;
                line-height: 1.83;
                color: $form-input-color;
                border-bottom: solid 0.5px #9b9b9b;
                background: transparent;
                -webkit-appearance: none;
                -webkit-appearance: none;
                box-shadow: none !important;
                &:-webkit-autofill {
                    color: #fff !important;
                }
                &:focus~.label,
                &:valid~.label,
                &:invalid~.label {
                    top: -6px;
                    left: 2px;
                    font-size: 12px;
                    line-height: 24px;
                    font-weight: 600;
                }
                &:focus~.bar {
                    width: 100%;
                }
                &:focus {
                    border-bottom: 2px solid #fff;
                }
            }
            .label {
                position: absolute;
                top: 19px;
                left: 4px;
                font-size: 18px;
                line-height: 1.83;
                color: $form-input-color;
                pointer-events: none;
            }
            .bar {
                display: block;
                position: absolute;
                bottom: 18px;
                left: 0px;
                width: 0px;
                height: 2px;
                border-radius: 2px;
                background: $theme-teal;
                pointer-events: none;
            }
            .error {
                display: none;
                position: absolute;
                right: -45px;
                top: 13px;
                width: 32px;
                height: 32px;
                background-color: red;
                border-radius: 50%;
                &:before,
                &:after {
                    position: absolute;
                    left: 15px;
                    content: ' ';
                    top: 7px;
                    height: 18px;
                    width: 2px;
                    background: white;
                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }
                &._show {
                    display: block;
                }
            }
        }
        .upload {
            cursor: pointer;
            position: relative;
            border: solid 0.5px #9b9b9b;
            margin: 15px 0 24px;
            .input-file {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
            }
            .icon {
                position: absolute;
                top: 17px;
                left: 22px;
            }
            .text {
                padding: 12px 11px 12px 63px;
                font-size: 14px;
                line-height: 2.36;
                letter-spacing: 0px;
                color: $form-input-color;
            }
        }
        .file-name {
            font-size: 12px;
            margin-bottom: 25px;
        }
        .success-msg {
            display: none;
            border-radius: 2px;
            margin-bottom: 25px;
            color: #3c763d;
            background-color: #dff0d8;
            border-color: #d6e9c6;
            padding: 15px;
        }
        .green-button {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.65;
            letter-spacing: 0px;
            padding: 8px 27px 7px 32px;
            position: relative;
            .loading-animation {
                display: none;
                height: 0;
                width: 0;
                padding: 10px;
                border: 5px solid $theme-teal;
                border-right-color: $white;
                border-radius: 22px;
                animation: rotate 1s infinite linear;
                position: absolute;
                left: 50px;
                top: 10px;
                pointer-events: all;
            }
            &._loading {
                color: $theme-teal;
                pointer-events: none;
                .loading-animation {
                    display: block;
                }
            }
        }
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}