.most-popular-treatments {
    margin-bottom: 44px;
    .wrapper {
        max-width: 1225px;
        margin: 0 auto;
        @media screen and (max-width: 1024px) {
            max-width: none;
            padding-left: 30px;
            padding-right: 30px;
        }
        .header {
            font-size: 30px;
            font-weight: 500;
            letter-spacing: 0px;
            text-align: center;
            margin: 106px 0 26px;
        }
        .content {
            font-size: 20px;
            line-height: 2;
            letter-spacing: 0px;
            text-align: center;
            max-width: 646px;
            margin: 0 auto 42px;
        }
        ul {
            display: grid;
            grid-template-columns: 33.3333% 33.3333% 33.3333%;
            @media screen and (max-width: 1024px) {
                grid-template-columns: 50% 50%;
            }
            @media screen and (max-width: 768px) {
                display: block;
            }
            li {
                width: 389px;
                height: 251px;
                text-align: center;
                border-radius: 6px;
                border: solid 1px #ceced1;
                margin: 26px auto 0;
                background-color: #f7fffe;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                @media screen and (max-width: 1024px) {
                    padding: 50px 0;
                    width: 300px;
                }
                @media screen and (max-width: 480px) {
                    width: 100%;
                    height: auto;
                }
                .img {
                    margin-bottom: 24px;
                    svg {
                        width: 90px;
                        height: 90px;
                    }
                }
                .title {
                    font-size: 23px;
                    line-height: 1.2;
                }
                @media screen and (min-width: 1023px) {
                    &:hover {
                        color: $theme-purple;
                        .img {
                            svg {
                                .default-fill-color {
                                    fill: $theme-purple;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}