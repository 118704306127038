.container {
    .showcase {
        padding-bottom: 49px;
        padding-top: 20px;
        @media screen and (max-width: 1024px) {
            padding: 0;
        }
        .wrapper {
            position: relative;
            max-width: 1193px;
            margin: 0 auto;
            height: 571px;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
                height: 642px;
            }
            .form-section {
                position: absolute;
                top: 0;
                right: 0;
            }
            .header-slick-container {
                max-width: 680px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                @media screen and (max-width: 1024px) {
                    display: block;
                    position: static;
                    transform: none;
                    padding: 120px 0 50px;
                }
                &._slick-inactive {
                    position: absolute;
                    top: 32%;
                    transform: translateY(-50%);
                }
                .heading {
                    &._none {
                        display: none;
                    }
                    h1 {
                        font-size: 60px;
                        font-weight: bold;
                        line-height: 0.98;
                        letter-spacing: -1px;
                        @media screen and (max-width: 1024px) {
                            font-size: 40px;
                        }
                    }
                    p {
                        padding: 41px 0 0;
                        font-size: 20px;
                        line-height: 2;
                        @media screen and (max-width: 1024px) {
                            font-size: 18px;
                            line-height: 1.8;
                            padding: 30px 0 0;
                        }
                    }
                }
            }
            .find-out {
                position: absolute;
                left: 0;
                top: 380px;
                font-size: 18px;
                font-weight: 500;
                @media screen and (max-width: 1024px) {
                    top: 530px;
                    font-size: 16px;
                    left: 30px;
                }
            }
            .blue-block {
                position: absolute;
                top: -9px;
                right: 215px;
                background-color: $theme-purple;
                width: 267px;
                height: 294px;
                box-shadow: 20px 30px 30px 0 rgba(0, 0, 0, 0.16);
                background-color: $theme-purple;
                border-top-left-radius: 15px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 15px;
                border-bottom-left-radius: 50px;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
                &._dos {
                    right: -20px;
                    bottom: 55px;
                    top: auto;
                }
            }
            .pink-block {
                position: absolute;
                bottom: 66px;
                right: 280px;
                background-color: $form-pink;
                width: 186px;
                height: 186px;
                border-radius: 68px;
                background-color: #fc57c9;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
            .bg {
                position: absolute;
                right: -200px;
                bottom: 0;
                z-index: -1;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }
    .see-how-it-works {
        background-color: #f7fdfc;
        padding: 44px 0 97px;
        text-align: center;
        @media screen and (max-width: 1024px) {
            padding: 30px 30px 50px;
        }
        .header {
            font-size: 30px;
            font-weight: 500;
            line-height: 1.97;
            letter-spacing: -0.8px;
            margin-bottom: 35px;
        }
        .youtube-video {
            position: relative;
            .youtube-bg-img {
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }
            .youtube-play {
                cursor: pointer;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                .youtube-play-img {
                    @media screen and (max-width: 1024px) {
                        width: 80px;
                    }
                    @media screen and (max-width: 768px) {
                        width: 65px;
                    }
                    @media screen and (max-width: 480px) {
                        width: 55px;
                    }
                }
            }
        }
    }
    .questions {
        padding: 77px 0 91px;
        @media screen and (max-width: 1024px) {
            padding: 47px 0;
        }
        .wrapper {
            max-width: 1172px;
            margin: 0 auto;
            @media screen and (max-width: 1024px) {
                max-width: none;
                padding-left: 30px;
                padding-right: 30px;
            }
            ul {
                display: grid;
                grid-template-columns: 33.3333% 33.3333% 33.3333%;
                @media screen and (max-width: 768px) {
                    display: block;
                }
                li {
                    text-align: center;
                    @media screen and (max-width: 1024px) {
                        margin-bottom: 50px;
                    }
                    .img {
                        img {
                            width: 50px;
                            height: 50px;
                        }
                    }
                    .text {
                        padding: 25px 0 23px;
                        font-size: 26px;
                        line-height: 1.54;
                        text-align: center;
                    }
                    .button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .green-white-button {
                            cursor: pointer;
                            border: solid 2px #79dbc5;
                            font-size: 18px;
                            font-weight: 500;
                            color: #1a6f5c;
                            padding: 17px 30px 15px;
                        }
                    }
                }
            }
        }
    }
    .success-stories {
        .wrapper {
            max-width: 1137px;
            margin: 0 auto 0;
            @media screen and (max-width: 1024px) {
                max-width: none;
                padding-left: 30px;
                padding-right: 30px;
            }
            .header {
                font-size: 30px;
                font-weight: 500;
                line-height: 1.97;
                letter-spacing: -0.8px;
                margin-bottom: 39px;
            }
            ul {
                display: grid;
                grid-template-columns: 50% 50%;
                @media screen and (max-width: 767px) {
                    display: block;
                }
                li {
                    margin-bottom: 50px;
                    width: 307px;
                    .img {
                        position: relative;
                        .youtube-play {
                            cursor: pointer;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .title {
                        font-size: 26px;
                        letter-spacing: -0.1px;
                        margin-top: 21px;
                        @media screen and (max-width: 767px) {
                            font-size: 18px;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
    .patient-types {
        padding: 61px 0 62px;
        background-color: #f7fdfc;
        .wrapper {
            max-width: 1153px;
            margin: 0 auto;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            .tabs {
                margin-left: auto;
                margin-right: auto;
                display: table;
                margin-bottom: 76px;
                @media screen and (max-width: 1024px) {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    text-align: center;
                }
                .tab-link {
                    float: left;
                    cursor: pointer;
                    &:first-child {
                        margin-right: 45px;
                        @media screen and (max-width: 1024px) {
                            margin-right: 0;
                        }
                    }
                    .__text {
                        font-size: 36px;
                        letter-spacing: 0px;
                        opacity: 0.6;
                        color: $black;
                        @media screen and (max-width: 1024px) {
                            font-size: 24px;
                        }
                    }
                    &._active {
                        .__text {
                            border-bottom: solid 1.5px #313561;
                            opacity: 1;
                            padding-bottom: 13px;
                        }
                    }
                }
            }
            .content-container {
                .tab-content {
                    display: none;
                    ul {
                        display: grid;
                        grid-template-columns: 25% 25% 25% 25%;
                        @media screen and (max-width: 1024px) {
                            display: block;
                        }
                        li {
                            text-align: center;
                            position: relative;
                            @media screen and (max-width: 1024px) {
                                margin-bottom: 50px;
                            }
                            .icon {
                                min-height: 84px;
                            }
                            .title {
                                font-family: $font-dos;
                                font-size: 20px;
                                font-weight: 500;
                                letter-spacing: 0px;
                                margin: 42px 0 22px;
                            }
                            .text {
                                font-family: $font-dos;
                                font-size: 14px;
                                line-height: 1.75;
                                letter-spacing: 0px;
                            }
                            .dash {
                                position: absolute;
                                top: 25px;
                                right: -70px;
                                @media screen and (max-width: 1024px) {
                                    display: none;
                                }
                            }
                        }
                    }
                    &._active {
                        display: block;
                    }
                    &.alt {
                        ul {
                            display: grid;
                            grid-template-columns: 20% 20% 20% 20% 20%;
                            @media screen and (max-width: 1024px) {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    .about-us-section {
        .wrapper {
            max-width: 1137px;
            margin: 0 auto 0;
            padding-top: 53px;
            border-top: solid 1px $border-grey-dos;
            @media screen and (max-width: 1024px) {
                max-width: none;
                padding-left: 30px;
                padding-right: 30px;
            }
            .header {
                font-size: 30px;
                font-weight: 500;
                letter-spacing: 0px;
                margin-bottom: 41px;
            }
            .title {
                font-family: $font-dos;
                font-size: 36px;
                letter-spacing: 0px;
                margin: 57px 0 32px;
            }
            .content {
                font-family: $font-dos;
                font-size: 18px;
                line-height: 2.22;
                letter-spacing: 0px;
            }
            .image {
                img {
                    @media screen and (max-width: 1024px) {
                        width: 100%;
                    }
                }
            }
            .know-more {
                cursor: pointer;
                margin: 30px 0 54px;
                line-height: 24px;
                font-size: 20px;
                color: #3b56a5;
            }
        }
    }
}

.nav-class-for-scroll {
    &.fixed-nav {
        padding-top: 119px;
        @media screen and (max-width: 1024px) {
            padding-top: 0;
        }
    }
}