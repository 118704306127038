.cost-benefits {
    .showcase {
        overflow: hidden;
        .wrapper {
            position: relative;
            max-width: 1193px;
            margin: 0 auto;
            height: 585px;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
                height: auto;
            }
            .showcase-container {
                display: grid;
                grid-template-columns: 50% 50%;
                @media screen and (max-width: 1024px) {
                    display: block;
                    padding: 120px 0 50px;
                    height: auto;
                }
                .heading-img {
                    @media screen and (max-width: 1024px) {
                        display: none;
                    }
                }
                .heading-wrapper {
                    display: flex;
                    align-items: center;
                    .heading {
                        max-width: 536px;
                        @media screen and (max-width: 1024px) {
                            max-width: none;
                        }
                    }
                    &._none {
                        display: none;
                    }
                    h1 {
                        font-size: 60px;
                        font-weight: bold;
                        line-height: 0.98;
                        letter-spacing: -1px;
                        @media screen and (max-width: 1024px) {
                            font-size: 54px;
                        }
                    }
                    p {
                        padding: 41px 0 0;
                        font-size: 20px;
                        line-height: 2;
                        @media screen and (max-width: 1024px) {
                            font-size: 18px;
                            line-height: 1.8;
                            padding: 30px 0 0;
                        }
                    }
                }
            }
        }
        .blue-block {
            position: absolute;
            top: -9px;
            right: 215px;
            background-color: $theme-purple;
            width: 267px;
            height: 294px;
            box-shadow: 20px 30px 30px 0 rgba(0, 0, 0, 0.16);
            background-color: $theme-purple;
            border-top-left-radius: 15px;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 50px;
            @media screen and (max-width: 1024px) {
                display: none;
            }
            &._dos {
                right: -20px;
                bottom: 55px;
                top: auto;
            }
        }
        .pink-block {
            position: absolute;
            bottom: 66px;
            right: 280px;
            background-color: $form-pink;
            width: 186px;
            height: 186px;
            border-radius: 68px;
            background-color: #fc57c9;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
        .bg {
            position: absolute;
            right: -100px;
            bottom: 0;
            z-index: -1;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    }
    .benefits {
        padding: 61px 0 30px;
        background-color: #f7fdfc;
        text-align: center;
        .wrapper {
            max-width: 1193px;
            margin: 0 auto;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            .title {
                font-size: 36px;
                letter-spacing: -0.04px;
                margin-bottom: 55px;
            }
            ul {
                display: grid;
                grid-template-columns: 25% 25% 25% 25%;
                @media screen and (max-width: 1024px) {
                    grid-template-columns: 50% 50%;
                }
                @media screen and (max-width: 768px) {
                    grid-template-columns: 100%;
                }
                &.level-two {
                    max-width: 871px;
                    margin: 70px auto 0;
                    grid-template-columns: 33.3333% 33.3333% 33.3333%;
                    @media screen and (max-width: 768px) {
                        grid-template-columns: 100%;
                    }
                }
                &.level-three {
                    max-width: 564px;
                    margin: 70px auto 0;
                    grid-template-columns: 50% 50%;
                    @media screen and (max-width: 600px) {
                        grid-template-columns: 100%;
                    }
                }
                li {
                    padding: 0 25px;
                    margin-bottom: 30px;
                    .icon {
                        margin-bottom: 40px;
                        img {
                            width: 55px;
                            height: 55px;
                        }
                    }
                    .text {
                        max-width: 257px;
                        margin: 0 auto;
                        font-family: $font-dos;
                        font-size: 16px;
                        line-height: 1.75;
                        letter-spacing: -0.03px;
                    }
                }
            }
        }
    }
    .steps {
        margin: 91px 0 92px;
        @media screen and (max-width: 1024px) {
            margin: 50px 0;
        }
        .wrapper {
            max-width: 1193px;
            margin: 0 auto;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            ul {
                .steps-container {
                    display: flex;
                    text-align: left;
                    margin-bottom: 130px;
                    align-items: center;
                    @media screen and (max-width: 1024px) {
                        display: block;
                        margin-bottom: 80px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &._reverse {
                        flex-direction: row-reverse;
                        .text {
                            padding-right: 45px;
                            @media screen and (max-width: 1024px) {
                                padding-right: 0;
                            }
                        }
                    }
                    .image {
                        display: flex;
                        flex: 1;
                        font-size: 127px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        text-align: center;
                        align-items: center;
                        @media screen and (max-width: 1024px) {
                            margin-bottom: 30px;
                        }
                        img {
                            text-align: center;
                            margin: 0 auto;
                            @media screen and (max-width: 480px) {
                                width: 100%;
                            }
                        }
                        .top-cancer-hospitals {
                            width: 357px;
                        }
                    }
                    .text {
                        flex: 1;
                        font-size: 30px;
                        line-height: 1.67;
                        padding-left: 45px;
                        @media screen and (max-width: 1024px) {
                            padding-left: 0;
                        }
                        .step-title {
                            font-size: 30px;
                            font-weight: 500;
                            line-height: 1.67;
                        }
                        .step-text {
                            margin-top: 37px;
                            font-size: 20px;
                            line-height: 2;
                        }
                    }
                }
            }
        }
    }
    .pmt-services {
        padding: 113px 0 93px;
        .wrapper {
            max-width: 1193px;
            margin: 0 auto;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            .title {
                margin-bottom: 13px;
                font-size: 30px;
                font-weight: 500;
                line-height: 1.67;
                letter-spacing: -0.01px;
            }
            .content {
                font-size: 20px;
                line-height: 2.1;
                ol {
                    li {
                        position: relative;
                        padding-left: 48px;
                        .balls {
                            position: absolute;
                            top: 7px;
                            left: 0;
                            width: 31px;
                            height: 31px;
                            background-color: #f5f5f7;
                            border-radius: 50%;
                            font-size: 16px;
                            line-height: 2.5;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
    .med-cost {
        margin-bottom: 90px;
        margin-top: -20px;
        .wrapper {
            max-width: 1137px;
            margin: 0 auto;
            @media screen and (max-width: 1280px) {
                padding: 0 30px;
            }
            .title {
                font-size: 36px;
                font-weight: 500;
                line-height: 1.67;
                letter-spacing: -0.01px;
                margin-bottom: 25px;
            }
            .content {
                font-size: 26px;
                line-height: 2;
                color: #000000;
            }
        }
    }
}

.free-consultation {
    padding: 48px 0 44px;
    background-color: $theme-teal;
    text-align: center;
    @media screen and (max-width: 1024px) {
        padding-left: 30px;
        padding-right: 30px;
    }
    p {
        line-height: 69px;
        font-size: 58px;
        color: $white;
        margin-bottom: 27px;
        @media screen and (max-width: 1024px) {
            line-height: 50px;
            font-size: 40px;
        }
        @media screen and (max-width: 768px) {
            line-height: 40px;
            font-size: 30px;
        }
    }
}