.why-container {
    .showcase {
        .purple-strip {
            padding: 90px 0 108px;
            background-color: $theme-purple;
            @media screen and (max-width: 1024px) {
                padding: 120px 0 50px;
            }
            .wrapper {
                max-width: 1193px;
                margin: 0 auto;
                position: relative;
                @media screen and (max-width: 1280px) {
                    max-width: none;
                    padding: 0 30px;
                }
                @media screen and (max-width: 1024px) {
                    padding: 0 30px;
                }
                .heading {
                    color: $white;
                    h1 {
                        margin-bottom: 5px;
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 1.48;
                        letter-spacing: -0.8px;
                        word-break: break-all;
                    }
                    p {
                        font-size: 26px;
                        line-height: 1.54;
                        max-width: 300px;
                    }
                    .bg {
                        position: absolute;
                        top: -89px;
                        right: -100px;
                        @media screen and (max-width: 1024px) {
                            display: none;
                        }
                        @media screen and (max-width: 1024px) {
                            display: none;
                        }
                    }
                }
                .form-section {
                    @media screen and (max-width: 1030px) {
                        right: 30px;
                    }
                }
            }
        }
        .green-strip {
            height: 80px;
            background-color: $theme-teal;
            position: relative;
            @media screen and (max-width: 768px) {
                height: 30px;
            }
            .nurse {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 3;
                @media screen and (max-width: 1280px) {
                    display: none;
                }
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }
    .achievements {
        .wrapper {
            max-width: 1117px;
            margin: 0 auto;
            ul {
                display: flex;
                padding: 185px 0 104px;
                margin-bottom: 116px;
                border-bottom: solid 1px $border-grey-dos;
                @media screen and (max-width: 1024px) {
                    padding: 80px 0 50px;
                    display: block;
                    margin-bottom: 80px;
                }
                li {
                    flex: 1;
                    text-align: center;
                    @media screen and (max-width: 1024px) {
                        margin-bottom: 50px;
                        padding: 0 30px;
                    }
                    .top {
                        line-height: 71px;
                        font-size: 60px;
                        font-weight: bold;
                        margin-bottom: 33px;
                    }
                    .bottom {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    .steps {
        padding-bottom: 291px;
        @media screen and (max-width: 1024px) {
            padding-bottom: 100px;
        }
        .wrapper {
            max-width: 1117px;
            margin: 0 auto;
            @media screen and (max-width: 1117px) {
                padding: 0 30px;
            }
            ul {
                .steps-container {
                    display: flex;
                    text-align: left;
                    margin-bottom: 150px;
                    align-items: center;
                    @media screen and (max-width: 1024px) {
                        display: block;
                        margin-bottom: 80px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &._reverse {
                        flex-direction: row-reverse;
                        .text {
                            padding-right: 10px;
                            @media screen and (max-width: 1024px) {
                                padding-right: 0;
                            }
                        }
                    }
                    .image {
                        flex: 1;
                        font-size: 127px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        text-align: center;
                        display: block;
                        @media screen and (max-width: 1024px) {
                            font-size: 80px;
                            margin-bottom: 25px;
                        }
                        img {
                            @media screen and (max-width: 480px) {
                                width: 100%;
                            }
                        }
                        .top-cancer-hospitals {
                            width: 357px;
                            @media screen and (max-width: 420px) {
                                width: 100%;
                            }
                        }
                    }
                    .text {
                        flex: 1;
                        font-size: 30px;
                        line-height: 1.67;
                        @media screen and (max-width: 1024px) {
                            font-size: 22px;
                        }
                        span {
                            font-weight: bold;
                        }
                        .know-more {
                            cursor: pointer;
                            margin-top: 10px;
                            line-height: 24px;
                            font-size: 20px;
                            color: #3b56a5;
                        }
                    }
                }
            }
        }
    }
    .free-consultation {
        padding: 48px 0 44px;
        background-color: $theme-teal;
        text-align: center;
        @media screen and (max-width: 1024px) {
            padding-left: 30px;
            padding-right: 30px;
        }
        p {
            line-height: 69px;
            font-size: 58px;
            color: $white;
            margin-bottom: 27px;
            @media screen and (max-width: 1024px) {
                line-height: 50px;
                font-size: 40px;
            }
            @media screen and (max-width: 768px) {
                line-height: 40px;
                font-size: 30px;
            }
        }
    }
}