.services {
    .know-more {
        display: none;
    }
    .showcase {
        .purple-strip {
            padding: 84px 0;
            background-color: $theme-purple;
            position: relative;
            @media screen and (max-width: 1024px) {
                padding: 120px 0 50px;
            }
            .wrapper {
                max-width: 1193px;
                margin: 0 auto;
                position: relative;
                @media screen and (max-width: 1280px) {
                    max-width: none;
                    padding: 0 30px;
                }
                @media screen and (max-width: 1024px) {
                    padding: 0 30px;
                }
                .heading {
                    color: $white;
                    h1 {
                        margin-bottom: 5px;
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 1.48;
                        letter-spacing: -0.8px;
                        word-break: break-all;
                    }
                    p {
                        font-size: 26px;
                        line-height: 1.54;
                        max-width: 333px;
                        @media screen and (max-width: 768px) {
                            max-width: none;
                        }
                    }
                    .bg {
                        position: absolute;
                        top: -89px;
                        right: -100px;
                    }
                }
                .form-section {
                    position: absolute;
                    top: -45px;
                    right: -30px;
                    @media screen and (max-width: 1280px) {
                        right: 30px;
                    }
                    @media screen and (max-width: 1024px) {
                        position: static;
                        margin-top: 40px;
                    }
                }
            }
        }
        .green-strip {
            height: 80px;
            background-color: $theme-teal;
            position: relative;
            @media screen and (max-width: 768px) {
                height: 30px;
            }
            .bg {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 3;
                max-height: 392px;
                @media screen and (max-width: 1280px) {
                    display: none;
                }
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }
    .page-content {
        padding: 150px 0 0;
        @media screen and (max-width: 1024px) {
            padding-top: 30px;
        }
        .wrapper {
            max-width: 1098px;
            margin: 0 auto;
            font-size: 20px;
            line-height: 1.5;
            @media screen and (max-width: 1200px) {
                max-width: none;
                width: 100%;
                padding: 0 30px;
            }
            @media screen and (max-width: 1024px) {
                max-width: none;
                padding: 0 35px;
            }
            @media screen and (max-width: 768px) {
                max-width: none;
                font-size: 18px;
                padding: 0 25px;
            }
        }
    }
    .free-consultation {
        padding: 48px 0 44px;
        background-color: $theme-teal;
        text-align: center;
        @media screen and (max-width: 1024px) {
            padding: 38px 0;
        }
        @media screen and (max-width: 768px) {
            padding: 38px 30px;
        }
        p {
            line-height: 69px;
            font-size: 58px;
            color: $white;
            margin-bottom: 27px;
            @media screen and (max-width: 1024px) {
                line-height: 50px;
                font-size: 40px;
            }
            @media screen and (max-width: 768px) {
                line-height: 40px;
                font-size: 30px;
            }
        }
    }
}