.error-page {
    .error-page-container {
        background: $theme-purple;
        padding: 66px 0 86px;
        text-align: center;
        color: $white;
        .wrapper {
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            .title {
                font-size: 40px;
                font-weight: bold;
                line-height: 1.48;
                letter-spacing: -0.7px;
            }
            .text {
                margin: 4px 0 25px;
                font-size: 26px;
                line-height: 1.54;
            }
        }
    }
    .green-strip {
        height: 30px;
        background: $theme-teal;
    }
}

.static-page {
    .showcase {
        .purple-strip {
            padding: 100px 0;
            background-color: $theme-purple;
            overflow: hidden;
            @media screen and (max-width: 1024px) {
                padding: 116px 30px 46px;
            }
            .wrapper {
                max-width: 1193px;
                margin: 0 auto;
                position: relative;
                .heading {
                    color: $white;
                    h1 {
                        margin-bottom: 5px;
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 1.48;
                        letter-spacing: -0.8px;
                        word-break: break-all;
                    }
                }
            }
        }
        .green-strip {
            height: 30px;
            background-color: $theme-teal;
        }
    }
    .title {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 30px;
        text-transform: uppercase;
    }
    .sub-title {
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 20px;
    }
    .static-container {
        margin: 100px 0;
        @media screen and (max-width: 1024px) {
            margin: 50px 0;
        }
        .wrapper {
            max-width: 1193px;
            margin: 0 auto;
            font-size: 18px;
            line-height: 1.5;
            @media screen and (max-width: 1280px) {
                padding: 0 30px;
                font-size: 16px;
            }
            a {
                color: blue;
                display: inline-block;
            }
            ul {
                margin-bottom: 30px;
                @media screen and (max-width: 1024px) {
                    margin-bottom: 20px;
                }
                li {
                    padding-left: 73px;
                    margin-bottom: 20px;
                    position: relative;
                    color: $black;
                    @media screen and (max-width: 1024px) {
                        padding-left: 43px;
                        margin-bottom: 20px;
                    }
                    .child-list {
                        margin: 0;
                        li {
                            position: relative;
                            font-size: 16px;
                            line-height: 1.8;
                            margin-bottom: 16px;
                            text-transform: capitalize;
                            @media screen and (max-width: 1024px) {
                                font-size: 14px;
                                padding-left: 30px;
                            }
                            .mini-bullet {
                                position: absolute;
                                top: 9px;
                                left: 50px;
                                width: 10px;
                                height: 10px;
                                background-color: $bullet-bg;
                                border-radius: 50%;
                                @media screen and (max-width: 1024px) {
                                    width: 8px;
                                    height: 8px;
                                    top: 8px;
                                    left: 8px;
                                }
                            }
                        }
                    }
                    ol.child-list {
                        margin: 0;
                        list-style: lower-alpha;
                        li {
                            font-size: 16px;
                            line-height: 1.8;
                            margin-bottom: 8px;
                            padding-left: 10px;
                            margin-left: 50px;
                            text-transform: capitalize;
                            .child-child {
                                margin-top: 20px;
                                list-style: lower-roman;
                            }
                        }
                    }
                    .bullet {
                        position: absolute;
                        top: 6px;
                        left: 40px;
                        width: 14px;
                        height: 14px;
                        background-color: $bullet-bg;
                        border-radius: 50%;
                        @media screen and (max-width: 1024px) {
                            top: 6px;
                            left: 15px;
                            width: 10px;
                            height: 10px;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}