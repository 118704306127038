.slick-dots {
    display: flex;
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;
    margin-top: 150px;
    @media screen and (max-width: 1024px) {
        margin-top: 80px;
    }
    li {
        margin: 0 0.25rem;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        button {
            cursor: pointer;
            display: block;
            width: 10px;
            height: 10px;
            padding: 0;
            border: none;
            border-radius: 100%;
            background-color: #707090;
            text-indent: -9999px;
            opacity: 0.3;
        }
        &.slick-active button {
            opacity: 1;
            outline: none;
        }
        &.slick-active button {
            outline: none;
        }
        &:focus {
            outline: none;
        }
    }
}

.slick-dots li:focus {
    button {
        outline: none;
    }
}


/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}