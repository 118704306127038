.about-us {
    .showcase {
        overflow: hidden;
        .wrapper {
            position: relative;
            max-width: 1193px;
            margin: 0 auto;
            height: 585px;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
                height: auto;
            }
            .showcase-container {
                display: grid;
                grid-template-columns: 50% 50%;
                @media screen and (max-width: 1024px) {
                    display: block;
                    padding: 120px 0 50px;
                    height: auto;
                }
                .heading-img {
                    @media screen and (max-width: 1024px) {
                        display: none;
                    }
                }
                .heading-wrapper {
                    display: flex;
                    align-items: center;
                    .heading {
                        max-width: 536px;
                        @media screen and (max-width: 1024px) {
                            max-width: none;
                        }
                    }
                    &._none {
                        display: none;
                    }
                    h1 {
                        font-size: 60px;
                        font-weight: bold;
                        line-height: 0.98;
                        letter-spacing: -1px;
                        @media screen and (max-width: 1024px) {
                            font-size: 40px;
                        }
                    }
                    p {
                        padding: 41px 0 0;
                        font-size: 20px;
                        line-height: 2;
                        @media screen and (max-width: 1024px) {
                            font-size: 18px;
                            line-height: 1.8;
                            padding: 30px 0 0;
                        }
                    }
                }
            }
        }
        .blue-block {
            position: absolute;
            top: -9px;
            right: 215px;
            background-color: $theme-purple;
            width: 267px;
            height: 294px;
            box-shadow: 20px 30px 30px 0 rgba(0, 0, 0, 0.16);
            background-color: $theme-purple;
            border-top-left-radius: 15px;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 50px;
            @media screen and (max-width: 1024px) {
                display: none;
            }
            &._dos {
                right: -20px;
                bottom: 55px;
                top: auto;
            }
        }
        .pink-block {
            position: absolute;
            bottom: 66px;
            right: 280px;
            background-color: $form-pink;
            width: 186px;
            height: 186px;
            border-radius: 68px;
            background-color: #fc57c9;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
        .bg {
            position: absolute;
            right: -100px;
            bottom: 0;
            z-index: -1;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    }
}

.steps {
    margin: 91px 0 92px;
    @media screen and (max-width: 1024px) {
        margin: 50px 0;
    }
    .wrapper {
        max-width: 1193px;
        margin: 0 auto;
        @media screen and (max-width: 1024px) {
            padding: 0 30px;
        }
        ul {
            .steps-container {
                display: flex;
                text-align: left;
                margin-bottom: 130px;
                align-items: center;
                @media screen and (max-width: 1024px) {
                    display: block;
                    margin-bottom: 80px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &._reverse {
                    flex-direction: row-reverse;
                    .text {
                        padding-right: 45px;
                        @media screen and (max-width: 1024px) {
                            padding-right: 0;
                        }
                    }
                }
                .image {
                    display: flex;
                    flex: 1;
                    font-size: 127px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    text-align: center;
                    align-items: center;
                    @media screen and (max-width: 1024px) {
                        margin-bottom: 30px;
                    }
                    img {
                        text-align: center;
                        margin: 0 auto;
                        @media screen and (max-width: 480px) {
                            width: 100%;
                        }
                    }
                    .top-cancer-hospitals {
                        width: 357px;
                    }
                }
                .text {
                    flex: 1;
                    font-size: 30px;
                    line-height: 1.67;
                    padding-left: 45px;
                    @media screen and (max-width: 1024px) {
                        padding-left: 0;
                    }
                    .step-title {
                        font-size: 30px;
                        font-weight: 500;
                        line-height: 1.67;
                    }
                    .step-text {
                        margin-top: 37px;
                        font-size: 20px;
                        line-height: 2;
                    }
                }
            }
        }
    }
}

.about-us-section {
    margin-bottom: 110px;
    @media screen and (max-width: 1024px) {
        margin-bottom: 50px;
    }
    .wrapper {
        max-width: 1137px;
        margin: 0 auto 0;
        @media screen and (max-width: 1024px) {
            padding: 0 30px;
        }
        .header {
            font-size: 30px;
            font-weight: 500;
            letter-spacing: 0px;
            margin-bottom: 41px;
            @media screen and (max-width: 1024px) {
                padding-left: 0;
            }
        }
        .image {
            img {
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }
        }
        .title {
            font-family: $font-dos;
            font-size: 36px;
            letter-spacing: 0px;
            margin: 57px 0 32px;
        }
        .content {
            font-family: $font-dos;
            font-size: 18px;
            line-height: 2.22;
            letter-spacing: 0px;
        }
    }
}

.free-consultation {
    padding: 48px 0 44px;
    background-color: $theme-teal;
    text-align: center;
    @media screen and (max-width: 1024px) {
        padding-left: 30px;
        padding-right: 30px;
    }
    p {
        line-height: 69px;
        font-size: 58px;
        color: $white;
        margin-bottom: 27px;
        @media screen and (max-width: 1024px) {
            line-height: 50px;
            font-size: 40px;
        }
        @media screen and (max-width: 768px) {
            line-height: 40px;
            font-size: 30px;
        }
    }
}

.we-got-you-covered {
    .wrapper {
        max-width: 1225px;
        margin: 0 auto;
        @media screen and (max-width: 1024px) {
            padding: 0 30px;
        }
        .title {
            font-size: 30px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 73px;
        }
        ul {
            display: grid;
            grid-template-columns: 389px 389px 389px;
            grid-column-gap: 29px;
            margin-bottom: 27px;
            @media screen and (max-width: 1024px) {
                grid-template-columns: 50% 50%;
                grid-column-gap: 20px;
            }
            @media screen and (max-width: 768px) {
                display: block;
            }
            li {
                padding: 65px 39px 52px 40px;
                text-align: center;
                border-radius: 6px;
                border: solid 1px #ceced1;
                margin-bottom: 43px;
                .icon {}
                .block-title {
                    font-size: 23px;
                    margin: 40px 0 30px;
                }
                .text {
                    font-size: 20px;
                    line-height: 2;
                }
            }
        }
    }
}