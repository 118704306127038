.services-sub-pages {
    .showcase {
        .purple-strip {
            padding: 46px 0 51px;
            background-color: $theme-purple;
            overflow: hidden;
            @media screen and (max-width: 1024px) {
                padding: 116px 30px 46px;
            }
            .wrapper {
                max-width: 1193px;
                margin: 0 auto;
                position: relative;
                .heading {
                    color: $white;
                    h1 {
                        margin-bottom: 5px;
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 1.48;
                        letter-spacing: -0.8px;
                        word-break: break-all;
                    }
                    p {
                        font-size: 26px;
                        line-height: 1.54;
                        max-width: 531px;
                    }
                    .bg {
                        position: absolute;
                        top: -89px;
                        right: -100px;
                        @media screen and (max-width: 1024px) {
                            display: none;
                        }
                    }
                    .landing-bg {
                        position: absolute;
                        top: -47px;
                        right: 85px;
                        width: 165px;
                        height: 242px;
                        @media screen and (max-width: 1024px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .services-nav {
        background-color: $theme-teal;
        position: relative;
        @media screen and (max-width: 1024px) {
            display: none;
        }
        ul {
            max-width: 1193px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: auto auto auto auto auto auto;
            text-align: center;
            li {
                padding: 20px 0;
                font-size: 18px;
                font-weight: 500;
                line-height: 3.33;
                color: $white;
                a {
                    &:hover {
                        color: $secondary-nav-active;
                    }
                }
                &._active {
                    a {
                        color: $secondary-nav-active;
                    }
                }
            }
        }
    }
    .page-title {
        margin: 85px 0 49px;
        @media screen and (max-width: 1024px) {
            margin: 35px 0;
        }
        .wrapper {
            max-width: 1098px;
            margin: 0 auto;
            font-size: 30px;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
                font-size: 26px;
            }
        }
    }
    .page-content {
        padding-bottom: 30px;
        .wrapper {
            max-width: 1098px;
            margin: 0 auto;
            font-size: 18px;
            line-height: 1.5;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
                font-size: 16px;
            }
            .main-list {
                margin-bottom: 80px;
                @media screen and (max-width: 1024px) {
                    margin-bottom: 20px;
                }
                li {
                    padding-left: 73px;
                    margin-bottom: 30px;
                    position: relative;
                    color: $black;
                    @media screen and (max-width: 1024px) {
                        padding-left: 43px;
                        margin-bottom: 20px;
                    }
                    .child-list {
                        margin-top: 22px;
                        li {
                            position: relative;
                            font-size: 16px;
                            line-height: 1.8;
                            margin-bottom: 16px;
                            @media screen and (max-width: 1024px) {
                                font-size: 14px;
                                padding-left: 30px;
                            }
                            .mini-bullet {
                                position: absolute;
                                top: 9px;
                                left: 50px;
                                width: 10px;
                                height: 10px;
                                background-color: $bullet-bg;
                                border-radius: 50%;
                                @media screen and (max-width: 1024px) {
                                    width: 8px;
                                    height: 8px;
                                    top: 8px;
                                    left: 8px;
                                }
                            }
                        }
                    }
                    .bullet {
                        position: absolute;
                        top: 6px;
                        left: 40px;
                        width: 14px;
                        height: 14px;
                        background-color: $bullet-bg;
                        border-radius: 50%;
                        @media screen and (max-width: 1024px) {
                            top: 6px;
                            left: 15px;
                            width: 10px;
                            height: 10px;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .img-section {
        margin-top: 20px;
        margin-bottom: 80px;
        @media screen and (max-width: 1024px) {
            margin-bottom: 50px;
        }
        .wrapper {
            max-width: 1098px;
            margin: 0 auto;
            position: relative;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            img {
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }
            .form-section {
                position: absolute;
                bottom: 15px;
                right: 0;
                top: auto;
                @media screen and (max-width: 1024px) {
                    position: static;
                }
            }
            .blue-block {
                position: absolute;
                top: -30px;
                right: 216px;
                background-color: $theme-purple;
                width: 267px;
                height: 294px;
                box-shadow: 20px 30px 30px 0 rgba(0, 0, 0, 0.16);
                background-color: $theme-purple;
                border-top-left-radius: 5px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 50px;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
            .pink-block {
                position: absolute;
                top: -8px;
                right: -14px;
                background-color: $form-pink;
                width: 186px;
                height: 186px;
                border-radius: 68px;
                background-color: #fc57c9;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }
    .next-previous-page {
        margin-bottom: 75px;
        @media screen and (max-width: 1024px) {
            margin-bottom: 50px;
        }
        .wrapper {
            max-width: 1009px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 486px 486px;
            grid-column-gap: 37px;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
                display: block;
            }
            .left,
            .right {
                cursor: pointer;
                width: 488px;
                height: 92px;
                border-radius: 6px;
                border: solid 1px #ceced1;
                background-color: $white;
                position: relative;
                font-size: 23px;
                letter-spacing: 0px;
                color: $black;
                a {
                    padding: 36px 0px;
                    @media screen and (max-width: 1024px) {
                        padding: 24px 0px;
                    }
                }
                @media screen and (max-width: 1024px) {
                    font-size: 18px;
                    width: 300px;
                    height: auto;
                    margin: 0 auto;
                }
            }
            .left {
                text-align: right;
                @media screen and (max-width: 1024px) {
                    margin-bottom: 30px;
                }
                a {
                    display: block;
                    padding-right: 47px;
                    @media screen and (max-width: 1024px) {
                        padding-right: 35px;
                    }
                }
                .arrow {
                    position: absolute;
                    top: 32px;
                    left: 28px;
                    @media screen and (max-width: 1024px) {
                        top: 24px;
                        left: 24px;
                        img {
                            width: 35px;
                        }
                    }
                }
            }
            .right {
                text-align: left;
                a {
                    display: block;
                    padding-left: 47px;
                    @media screen and (max-width: 1024px) {
                        padding-left: 35px;
                    }
                }
                .arrow {
                    position: absolute;
                    top: 32px;
                    right: 28px;
                    @media screen and (max-width: 1024px) {
                        top: 24px;
                        right: 24px;
                        img {
                            width: 35px;
                        }
                    }
                }
            }
        }
    }
    .free-consultation {
        padding: 48px 0 44px;
        background-color: $theme-teal;
        text-align: center;
        @media screen and (max-width: 1024px) {
            padding: 38px 0;
        }
        @media screen and (max-width: 768px) {
            padding: 38px 30px;
        }
        p {
            line-height: 69px;
            font-size: 58px;
            color: $white;
            margin-bottom: 27px;
            @media screen and (max-width: 1024px) {
                line-height: 50px;
                font-size: 40px;
            }
            @media screen and (max-width: 768px) {
                line-height: 40px;
                font-size: 30px;
            }
        }
    }
}