header {
    &._fixed {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 222;
        width: 100%;
        background: $white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
        @media screen and (max-width: 1024px) {
            position: static;
        }
    }
    .wrapper {
        max-width: 1193px;
        margin: 0 auto;
        @media screen and (max-width: 1280px) {
            max-width: none;
            padding: 0 30px;
        }
        .nav-container {
            width: 100%;
            position: relative;
            display: grid;
            grid-template-columns: 227px auto;
            @media screen and (max-width: 1024px) {
                display: none;
            }
            .logo {
                display: flex;
                align-items: center;
            }
            nav {
                width: 100%;
                padding-top: 28px;
                .contact-nav {
                    padding-bottom: 21px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .nav-element {
                        display: inline-block;
                        line-height: 14px;
                        font-size: 12px;
                        margin-right: 10px;
                        cursor: pointer;
                        &._active,
                        &:hover {
                            color: $theme-purple;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    .whats-app {
                        font-family: $font-dos;
                        font-size: 13px;
                        margin-right: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .wa-logo {
                            padding-right: 8px;
                            .viber {
                                width: 45px;
                            }
                            .width20 {
                                width: 20px;
                            }
                        }
                        .wa-number {
                            font-weight: bold;
                            margin-left: 10px;
                        }
                    }
                }
                .main-nav {
                    display: flex;
                    justify-content: flex-end;
                    .nav-element {
                        position: relative;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 19px;
                        padding: 0 9px 25px;
                        cursor: pointer;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                            padding-right: 0;
                        }
                        &:hover,
                        &._active {
                            color: $theme-purple;
                        }
                        .sub-nav-label {
                            &._active {
                                color: $theme-purple;
                            }
                        }
                        .sub-nav {
                            width: 332px;
                            position: absolute;
                            top: 54px;
                            left: 50%;
                            transform: translate(-50%, 0);
                            border-radius: 8px;
                            box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.1);
                            border: solid 1px #e1e1e1;
                            background-color: $white;
                            padding: 34px 35px 31px 35px;
                            z-index: 100;
                            opacity: 0;
                            transition: visibility .15s, opacity .3s ease, transform .25s;
                            visibility: hidden;
                            &._show {
                                opacity: 1;
                                transition: visibility 0s, opacity .3s ease, transform .25s;
                                visibility: visible;
                            }
                            .arrow-up {
                                z-index: 101;
                                position: absolute;
                                top: -15px;
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                                width: 0;
                            }
                            ul {
                                li {
                                    font-size: 14px;
                                    line-height: 1.4;
                                    margin-bottom: 20px;
                                    color: $black;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    &._active,
                                    &:hover {
                                        color: $theme-purple;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .nav-container-responsive {
            display: none;
            @media screen and (max-width: 1024px) {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 222;
                width: 100%;
                background-color: $white;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
            }
            .wrapper {
                padding: 13px 30px;
                .row {
                    display: grid;
                    grid-template-columns: 80% 20%;
                    .logo {
                        img {
                            width: 185px;
                        }
                    }
                    .hamburger-menu {
                        margin: 13px 0;
                        position: relative;
                        cursor: pointer;
                        &:after {
                            display: block;
                            content: '';
                            clear: both;
                        }
                        .hamburger-menu-wrapper {
                            position: absolute;
                            top: 0;
                            right: -20px;
                        }
                        .arrow-header {
                            display: none;
                            position: absolute;
                            top: 35px;
                            right: 12px;
                        }
                        .bar1,
                        .bar2,
                        .bar3 {
                            width: 30px;
                            height: 2px;
                            background-color: $black;
                            transition: 0.4s;
                            margin: 6px 20px 6px auto;
                        }
                        .bar1 {
                            margin-top: 0;
                        }
                        .bar3 {
                            margin-bottom: 0;
                        }
                        &.change {
                            .arrow-header {
                                display: block;
                            }
                            .bar1 {
                                transform: rotate(-45deg) translate(-4px, 6px);
                            }
                            .bar2 {
                                opacity: 0;
                            }
                            .bar3 {
                                transform: rotate(45deg) translate(-4px, -8px);
                            }
                        }
                    }
                }
            }
        }
        .responsive-nav-options {
            display: none;
            @media screen and (max-width: 1024px) {
                width: 310px;
                position: fixed;
                top: 0;
                right: 0;
                z-index: 100;
                background-color: $white;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
                height: 100vh;
                padding-top: 70px;
                transform: translateX(100%);
                transition: all 0.5s ease-out;
                overflow-y: scroll;
            }
            &.show {
                @media screen and (max-width: 1024px) {
                    display: block;
                }
            }
            .wrapper {
                padding: 0 30px;
                .nav-element {
                    padding: 20px 0;
                    border-bottom: 1px solid #d6dbdb;
                    font-size: 18px;
                    position: relative;
                    .sub-nav-label {
                        padding-right: 20px;
                        &._active {
                            color: $theme-purple;
                        }
                    }
                    .sub-nav {
                        display: none;
                        &.open {
                            display: block;
                        }
                    }
                    .plus-minus {
                        position: absolute;
                        top: 22px;
                        right: 0px;
                        cursor: pointer;
                        .plus {
                            display: block;
                        }
                        .minus {
                            display: none;
                        }
                        &.open {
                            .plus {
                                display: none;
                            }
                            .minus {
                                display: block;
                                margin-top: -6px;
                            }
                        }
                    }
                    &._active {
                        color: $theme-purple;
                    }
                    ul {
                        padding-top: 20px;
                        padding-left: 20px;
                        li {
                            font-size: 14px;
                            line-height: 20px;
                            padding: 10px 0;
                        }
                    }
                }
            }
            &.open {
                transform: translateX(0);
            }
        }
    }
}