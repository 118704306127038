.our-services {
    margin: 49px 0 67px;
    @media screen and (max-width: 1024px) {
        margin: 30px 0;
    }
    .wrapper {
        max-width: 1137px;
        margin: 0 auto 0;
        @media screen and (max-width: 1280px) {
            max-width: none;
            padding: 0 50px;
        }
        @media screen and (max-width: 1024px) {
            max-width: none;
            padding: 0 35px;
        }
        @media screen and (max-width: 768px) {
            max-width: none;
            padding: 0 25px;
        }
        .header {
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 49px;
            padding-left: 15px;
            @media screen and (max-width: 768px) {
                font-size: 26px;
                margin-bottom: 35px;
            }
        }
        ul {
            display: grid;
            grid-template-columns: 33% 33% 33%;
            @media screen and (max-width: 1280px) {
                grid-template-columns: 50% 50%;
            }
            @media screen and (max-width: 767px) {
                grid-template-columns: 100%;
            }
            li {
                margin-top: 23px;
                cursor: pointer;
                width: 322px;
                @media screen and (max-width: 380px) {
                    width: 100%;
                }
                .services-block {
                    .img {
                        position: relative;
                        overflow: hidden;
                        width: 100%;
                        height: 322px;
                        @media screen and (max-width: 380px) {
                            width: 100%;
                            height: 322px;
                        }
                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: 0.6s ease;
                        }
                    }
                    .title {
                        font-size: 24px;
                        line-height: 3;
                        @media screen and (max-width: 768px) {
                            font-size: 22px;
                            line-height: 2;
                        }
                    }
                    @media screen and (min-width: 1023px) {
                        &:hover {
                            .title {
                                color: $theme-purple;
                            }
                            .img {
                                img {
                                    transform: scale(1.2);
                                }
                            }
                        }
                    }
                }
            }
        }
        .know-more {
            cursor: pointer;
            margin-top: 47px;
            line-height: 24px;
            font-size: 20px;
            color: $theme-purple;
            @media screen and (max-width: 1024px) {
                line-height: 20px;
                font-size: 16px;
            }
        }
    }
}