.overlay-video {
    display: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    transition: opacity 600ms ease-in;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .7);
    z-index: 999999;
    .videoWrapperExt {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 982px;
        padding: 0 30px;
    }
    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .close {
        background-image: url(http://djit.ac/assets/images/news/mark.png);
        position: absolute;
        top: -50px;
        right: -50px;
        cursor: pointer;
        z-index: 9999;
        height: 40px;
        width: 40px;
        background-size: 40px;
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    /* demo purpose */
    .start {
        text-align: center;
        font-size: 40px;
        text-decoration: underline;
        color: blue;
        text-transform: uppercase;
        cursor: pointer;
    }
}

.o1 {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
    transition: opacity 600ms ease-out;
}