.contact-us {
    .know-more {
        display: none;
    }
    .most-popular-treatments {
        @media screen and (max-width: 1024px) {
            margin: 25px 0;
        }
        .wrapper {
            .header {
                @media screen and (max-width: 1024px) {
                    margin: 50px 0;
                }
            }
        }
    }
    .showcase {
        .purple-strip {
            padding: 75px 0;
            background-color: $theme-purple;
            position: relative;
            @media screen and (max-width: 1024px) {
                padding: 120px 0 50px;
            }
            .wrapper {
                max-width: 1193px;
                margin: 0 auto;
                position: relative;
                @media screen and (max-width: 1280px) {
                    max-width: none;
                    padding: 0 30px;
                }
                @media screen and (max-width: 1024px) {
                    padding: 0 30px;
                }
                .heading {
                    color: $white;
                    h1 {
                        margin-bottom: 5px;
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 1.48;
                        letter-spacing: -0.8px;
                        word-break: break-all;
                    }
                    p {
                        font-size: 26px;
                        line-height: 1.54;
                        max-width: 333px;
                    }
                    .bg {
                        position: absolute;
                        top: -89px;
                        right: -136px;
                    }
                }
                .form-section {
                    position: absolute;
                    top: -45px;
                    right: -30px;
                }
            }
        }
        .green-strip {
            height: 80px;
            background-color: $theme-teal;
            position: relative;
            @media screen and (max-width: 768px) {
                height: 30px;
            }
            .bg {
                position: absolute;
                bottom: 0;
                left: -133px;
                right: 0;
                margin: 0 auto;
                z-index: 3;
                @media screen and (max-width: 1280px) {
                    display: none;
                }
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }
    .contact-details {
        margin-top: 190px;
        @media screen and (max-width: 1024px) {
            margin-top: 100px;
        }
        .wrapper {
            max-width: 1080px;
            margin: 0 auto;
            @media screen and (max-width: 1280px) {
                max-width: none;
                padding: 0 30px;
            }
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            .row {
                display: grid;
                grid-template-columns: 60% 40%;
                @media screen and (max-width: 1024px) {
                    display: block;
                }
                .contact {
                    .contact-details-wrapper {
                        display: grid;
                        grid-template-columns: calc(50% - 42px) calc(50% - 42px);
                        grid-column-gap: 84px;
                        @media screen and (max-width: 768px) {
                            display: block;
                        }
                        li.contact-el {
                            margin-bottom: 60px;
                            .title {
                                font-size: 30px;
                                font-weight: bold;
                                letter-spacing: -0.5px;
                                color: $theme-purple;
                                margin-bottom: 6px;
                            }
                            .text {
                                font-size: 20px;
                                line-height: 2;
                                .social-media {
                                    display: flex;
                                    align-items: center;
                                    margin-top: 12px;
                                    li {
                                        display: flex;
                                        justify-content: center;
                                        margin-right: 22px;
                                        cursor: pointer;
                                        &:last-child {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .map {
                    width: 100%;
                    overflow: hidden;
                    height: 252px;
                    @media screen and (max-width: 1024px) {
                        margin-bottom: 60px;
                    }
                }
            }
        }
    }
    .free-consultation {
        margin-top: 60px;
        padding: 48px 0 44px;
        background-color: $theme-teal;
        text-align: center;
        @media screen and (max-width: 1024px) {
            margin-top: 80px;
            padding: 38px 0;
        }
        @media screen and (max-width: 768px) {
            margin-top: 80px;
            padding: 38px 30px;
        }
        p {
            line-height: 69px;
            font-size: 58px;
            color: $white;
            margin-bottom: 27px;
            @media screen and (max-width: 1024px) {
                line-height: 50px;
                font-size: 40px;
            }
            @media screen and (max-width: 768px) {
                line-height: 40px;
                font-size: 30px;
            }
        }
    }
    .treatments-landing {
        margin-top: 240px;
        @media screen and (max-width: 1024px) {
            margin-top: 0px;
        }
    }
}