/* rubik-300 - latin */

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/rubik-v8-latin-300.eot');
    /* IE9 Compat Modes */
    src: local('Rubik Light'), local('Rubik-Light'), url('../fonts/rubik-v8-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v8-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v8-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v8-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v8-latin-300.svg#Rubik') format('svg');
    /* Legacy iOS */
}


/* rubik-regular - latin */

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/rubik-v8-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Rubik'), local('Rubik-Regular'), url('../fonts/rubik-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v8-latin-regular.svg#Rubik') format('svg');
    /* Legacy iOS */
}


/* rubik-500 - latin */

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/rubik-v8-latin-500.eot');
    /* IE9 Compat Modes */
    src: local('Rubik Medium'), local('Rubik-Medium'), url('../fonts/rubik-v8-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v8-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v8-latin-500.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v8-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v8-latin-500.svg#Rubik') format('svg');
    /* Legacy iOS */
}


/* rubik-700 - latin */

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/rubik-v8-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Rubik Bold'), local('Rubik-Bold'), url('../fonts/rubik-v8-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v8-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v8-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v8-latin-700.svg#Rubik') format('svg');
    /* Legacy iOS */
}


/* rubik-900 - latin */

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/rubik-v8-latin-900.eot');
    /* IE9 Compat Modes */
    src: local('Rubik Black'), local('Rubik-Black'), url('../fonts/rubik-v8-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/rubik-v8-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/rubik-v8-latin-900.woff') format('woff'), /* Modern Browsers */
    url('../fonts/rubik-v8-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/rubik-v8-latin-900.svg#Rubik') format('svg');
    /* Legacy iOS */
}


/* roboto-300 - latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-v19-latin-300.eot');
    /* IE9 Compat Modes */
    src: local('Roboto Light'), local('Roboto-Light'), url('../fonts/roboto-v19-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v19-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v19-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v19-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v19-latin-300.svg#Roboto') format('svg');
    /* Legacy iOS */
}


/* roboto-regular - latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v19-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'), url('../fonts/roboto-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v19-latin-regular.svg#Roboto') format('svg');
    /* Legacy iOS */
}


/* roboto-500 - latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/roboto-v19-latin-500.eot');
    /* IE9 Compat Modes */
    src: local('Roboto Medium'), local('Roboto-Medium'), url('../fonts/roboto-v19-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v19-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v19-latin-500.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v19-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v19-latin-500.svg#Roboto') format('svg');
    /* Legacy iOS */
}


/* roboto-700 - latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-v19-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'), url('../fonts/roboto-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v19-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v19-latin-700.svg#Roboto') format('svg');
    /* Legacy iOS */
}


/* roboto-900 - latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/roboto-v19-latin-900.eot');
    /* IE9 Compat Modes */
    src: local('Roboto Black'), local('Roboto-Black'), url('../fonts/roboto-v19-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v19-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-v19-latin-900.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-v19-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-v19-latin-900.svg#Roboto') format('svg');
    /* Legacy iOS */
}

$font-uno: 'Rubik',
sans-serif;
$font-dos: 'Roboto',
sans-serif;
body,
html {
    font-family: $font-uno;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: $black;
}