// Colors
$black: #000000;
$white: #FFFFFF;
$theme-teal: #4acebd;
$theme-purple: #3b56a5;
$footer-bg-color: #f5f5f7;
$footer-policy-color: #3a3c3c;
$form-input-color: #4a4a4a;
$form-pink: #fc57c9;
$border-grey: #d3d3d3;
$border-grey-dos: #979797;
$secondary-nav-active: $theme-purple;
$bullet-bg: #3b56a5;
.bold {
    font-weight: bold;
}

.default-fill-color {
    fill: #231F20
}

.white-button {
    cursor: pointer;
    padding: 19px 54px 18px 55px;
    background-color: $white;
    line-height: 33px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: $theme-teal;
    display: inline-block;
    @media screen and (max-width: 1024px) {
        font-size: 18px;
        padding: 15px 38px 14px 39px;
    }
}

.green-button {
    cursor: pointer;
    padding: 19px 54px 18px 55px;
    background-color: $theme-teal;
    line-height: 33px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: $white;
    display: inline-block;
    @media screen and (max-width: 1024px) {
        font-size: 18px;
        padding: 15px 38px 14px 39px;
    }
}

.bold {
    font-weight: bold;
}

.visibility-hidden {
    visibility: hidden;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}