.our-docs {
    .know-more {
        display: none;
    }
    .showcase {
        .purple-strip {
            padding: 95px 0;
            background-color: $theme-purple;
            position: relative;
            &._doctors {
                padding: 139px 0;
            }
            @media screen and (max-width: 1024px) {
                padding: 120px 0 50px;
            }
            .wrapper {
                max-width: 1193px;
                margin: 0 auto;
                position: relative;
                @media screen and (max-width: 1280px) {
                    max-width: none;
                    padding: 0 30px;
                }
                @media screen and (max-width: 1024px) {
                    padding: 0 30px;
                }
                .heading {
                    color: $white;
                    h1 {
                        margin-bottom: 5px;
                        font-size: 40px;
                        font-weight: bold;
                        line-height: 1.48;
                        letter-spacing: -0.8px;
                        word-break: break-all;
                    }
                    p {
                        font-size: 26px;
                        line-height: 1.54;
                        max-width: 333px;
                        @media screen and (max-width: 768px) {
                            max-width: none;
                        }
                    }
                    .bg {
                        position: absolute;
                        top: -89px;
                        right: -136px;
                    }
                }
                .form-section {
                    position: absolute;
                    top: -90px;
                    right: -30px;
                    @media screen and (max-width: 1280px) {
                        right: 30px;
                    }
                    @media screen and (max-width: 1024px) {
                        position: static;
                        margin-top: 40px;
                    }
                }
            }
        }
        .green-strip {
            height: 80px;
            background-color: $theme-teal;
            position: relative;
            @media screen and (max-width: 768px) {
                height: 30px;
            }
            .bg {
                position: absolute;
                bottom: 0px;
                left: -133px;
                right: 0;
                margin: 0 auto;
                z-index: 1;
                @media screen and (max-width: 1280px) {
                    display: none;
                }
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
        }
    }
    .doctor-listing {
        margin-top: 131px;
        min-height: 860px;
        @media screen and (max-width: 1024px) {
            margin-top: 30px;
            padding: 0 30px;
        }
        .wrapper {
            max-width: 1129px;
            margin: 172px auto 0;
            @media screen and (max-width: 1024px) {
                margin: 50px auto 0;
            }
            .title {
                font-size: 30px;
                font-weight: 500;
                margin-bottom: 30px;
            }
            .row {
                display: grid;
                grid-template-columns: 197px 197px 197px 197px 197px;
                grid-column-gap: 36px;
                @media screen and (max-width: 1024px) {
                    grid-template-columns: 33.3333% 33.3333% 33.3333%;
                }
                @media screen and (max-width: 768px) {
                    grid-template-columns: 50% 50%;
                }
                @media screen and (max-width: 480px) {
                    display: block;
                }
                li {
                    margin-bottom: 40px;
                    @media screen and (max-width: 1024px) {
                        width: 197px;
                    }
                    .cancer-title {
                        font-size: 20px;
                        line-height: 1.5;
                        margin-top: 20px;
                    }
                    .img {
                        position: relative;
                        overflow: hidden;
                        width: 197px;
                        height: 197px;
                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: 0.6s ease;
                        }
                    }
                    @media screen and (min-width: 1023px) {
                        &:hover {
                            .cancer-title {
                                color: $theme-purple;
                            }
                            .img {
                                img {
                                    transform: scale(1.2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .doctors-content {
        margin-top: 188px;
        @media screen and (max-width: 991px) {
            margin-top: 50px;
        }
        .wrapper {
            max-width: 1105px;
            margin: 0 auto;
            position: relative;
            display: grid;
            grid-template-columns: 330px auto;
            grid-column-gap: 40px;
            @media screen and (max-width: 1280px) {
                padding: 0 30px;
            }
            @media screen and (max-width: 991px) {
                display: block;
            }
            .profile {
                @media screen and (max-width: 991px) {
                    width: 330px;
                    margin: 0 auto 50px;
                }
                @media screen and (max-width: 380px) {
                    width: 90%;
                    margin: 0 auto 50px;
                }
                .profile-wrapper {
                    border: solid 1px #c1c1c1;
                    padding: 40px 0 37px;
                    text-align: center;
                    .img {}
                    .name {
                        font-family: Rubik;
                        font-size: 30px;
                        font-weight: 500;
                        letter-spacing: -0.01px;
                        margin: 40px 0;
                        padding: 0 66px;
                    }
                    .experience {
                        font-family: Rubik;
                        font-size: 20px;
                        line-height: 1.5;
                        letter-spacing: -0.02px;
                        color: #999999;
                        padding: 0 24px;
                        .exp-text {
                            color: $black;
                        }
                    }
                    .special {
                        margin-top: 44px;
                        font-family: Rubik;
                        font-size: 20px;
                        line-height: 1.5;
                        letter-spacing: -0.02px;
                        color: #999999;
                        padding: 0 24px;
                    }
                }
            }
        }
    }
    .accolades {
        .intro-msg {
            font-size: 30px;
            line-height: 1.77;
            @media screen and (max-width: 380px) {
                font-size: 24px;
            }
        }
        .accolade-list {
            margin: 20px 0 12px;
            .title {
                font-size: 30px;
                margin-bottom: 30px;
                @media screen and (max-width: 380px) {
                    font-size: 26px;
                }
            }
            ul {
                margin-bottom: 30px;
                li {
                    font-size: 20px;
                    line-height: 1.8;
                    letter-spacing: -0.02px;
                    position: relative;
                    padding-left: 30px;
                    @media screen and (max-width: 380px) {
                        font-size: 16px;
                    }
                    .bullet {
                        position: absolute;
                        top: 13px;
                        left: 0px;
                        width: 10px;
                        height: 10px;
                        background-color: $bullet-bg;
                        border-radius: 50%;
                        @media screen and (max-width: 380px) {
                            top: 8px;
                        }
                    }
                }
            }
        }
    }
    .free-consultation {
        margin-top: 63px;
        padding: 48px 0 44px;
        background-color: $theme-teal;
        text-align: center;
        @media screen and (max-width: 1024px) {
            margin-top: 55px;
            padding: 38px 0;
        }
        @media screen and (max-width: 768px) {
            margin-top: 35px;
            padding: 38px 30px;
        }
        p {
            line-height: 69px;
            font-size: 58px;
            color: $white;
            margin-bottom: 27px;
            @media screen and (max-width: 1024px) {
                line-height: 50px;
                font-size: 40px;
            }
            @media screen and (max-width: 768px) {
                line-height: 40px;
                font-size: 30px;
            }
        }
    }
    .know-your-cancer-container {
        margin-top: 120px;
        @media screen and (max-width: 1024px) {
            margin-top: 60px;
        }
        .wrapper {
            max-width: 1193px;
            margin: 0 auto 0;
            @media screen and (max-width: 1024px) {
                padding: 0 30px;
            }
            .row {
                display: grid;
                grid-template-columns: auto 806px;
                @media screen and (max-width: 1024px) {
                    display: block;
                }
                .know-you-cancer-nav {
                    @media screen and (max-width: 1024px) {
                        display: none;
                    }
                    ul {
                        li {
                            margin-bottom: 26px;
                            font-size: 16px;
                            letter-spacing: 0px;
                            a {
                                &:hover {
                                    color: $theme-purple;
                                }
                            }
                            &._active {
                                a {
                                    color: $theme-purple;
                                }
                            }
                        }
                    }
                }
                .know-you-cancer-content {
                    ul li {
                        position: relative;
                        padding-left: 67px;
                        @media screen and (max-width: 1024px) {
                            padding-left: 40px;
                        }
                        .bullet {
                            position: absolute;
                            top: 10px;
                            left: 40px;
                            width: 10px;
                            height: 10px;
                            background-color: $bullet-bg;
                            border-radius: 50%;
                            @media screen and (max-width: 1024px) {
                                left: 10px;
                            }
                        }
                    }
                    .know-you-cancer-title {
                        font-size: 30px;
                    }
                    .know-you-cancer-img {
                        img {
                            @media screen and (max-width: 1024px) {
                                width: 100%;
                            }
                        }
                    }
                    .know-you-cancer-text {
                        font-size: 20px;
                        line-height: 1.5;
                        margin: 35px 0 40px;
                    }
                    .know-you-cancer-points {
                        margin-top: 40px;
                        font-size: 20px;
                        line-height: 1.5;
                        .content-title {
                            font-weight: bold;
                        }
                        .content-text {
                            margin: 25px 0;
                        }
                        .mt {
                            margin-top: 25px;
                        }
                        .mb {
                            margin-bottom: 25px;
                        }
                    }
                }
            }
        }
    }
    .treatments-landing {
        margin-top: 240px;
    }
}